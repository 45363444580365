.field-file__filename, .field-select__placeholder, .field-select__value, .field-video__info-label, .field-video__info-placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

g-menu-box {
  display: block;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 5px 0;
  max-width: 230px;
  margin: 5px 0;
  border-radius: 4px; }
  g-menu-box.-scroll {
    max-height: 300px;
    overflow-y: auto; }

.g-menu-mount {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  display: flex;
  flex-direction: column; }
  .g-menu-mount.-fixed {
    position: fixed; }
  .g-menu-mount.-align-top {
    transform-origin: center bottom; }
  .g-menu-mount.-align-top-left {
    transform-origin: left bottom; }
  .g-menu-mount.-align-top-right {
    transform-origin: right bottom; }
  .g-menu-mount.-align-bottom {
    transform-origin: center top; }
  .g-menu-mount.-align-bottom-left {
    transform-origin: left top; }
  .g-menu-mount.-align-bottom-right {
    transform-origin: right top; }
  .g-menu-mount.-align-bottom-attach {
    transform-origin: center top; }
  .g-menu-mount.-visible-add, .g-menu-mount.-visible-remove {
    transition: all 0.1s ease-out; }
  .g-menu-mount.-visible-add {
    opacity: 0;
    transform: scale(0.8); }
  .g-menu-mount.-visible, .g-menu-mount.-visible-add.-visible-add-active {
    opacity: 1;
    transform: scale(1); }
  .g-menu-mount.-visible-remove.-visible-remove-active {
    opacity: 0;
    transform: scale(0.8); }

.g-menu-item {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  color: #555459; }
  .g-menu-item__icon {
    margin: 0 4px -3px -4px; }
  .g-menu-item__image {
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background-color: rgba(0, 0, 0, 0.05);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 2px 8px 2px -3px;
    flex-shrink: 0; }
  .g-menu-item__text-wrapper {
    display: flex;
    width: 100%; }
  .g-menu-item__text {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.1; }
  .g-menu-item__sub-text {
    color: #a7abb1;
    flex-grow: 1;
    font-size: 12px;
    padding: 0 0 0 20px;
    text-align: right; }
  .g-menu-item:active, .g-menu-item:focus {
    color: #555459; }
  .g-menu-item:hover:not(.-disabled) {
    cursor: pointer;
    color: #555459;
    background: rgba(0, 0, 0, 0.03); }
  .g-menu-item.-disabled {
    color: rgba(85, 84, 89, 0.4); }
  .g-menu-item.-highlight:not(.-disabled) {
    color: #3aa3e3; }

.g-menu {
  display: block; }
  .g-menu.-inline {
    display: inline-block; }

g-menu-seperator {
  display: block;
  margin: 5px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05); }
  g-menu-seperator:first-child, g-menu-seperator:last-child {
    display: none; }

.gl-image-input {
  background: #fff;
  border: 1px solid #e5e9ec;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  min-height: 37px;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .gl-image-input-close {
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 5px;
    text-align: center;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    top: 0; }
  .gl-image-input-hint {
    bottom: 0;
    font-size: em(14);
    height: 14px;
    left: 0;
    line-height: 1;
    margin: auto;
    color: rgba(0, 0, 0, 0.4);
    position: absolute;
    right: 0;
    text-align: center;
    top: 0; }
  .gl-image-input-input {
    -webkit-appearance: none;
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }
  .gl-image-input-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0; }
  .gl-image-input-error {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .gl-image-input-error-msg {
    color: #eb4d5c;
    font-size: 11px;
    padding: 20px;
    text-align: center; }

.logo-image-input {
  max-width: 120px; }

.gl-img-view-base {
  cursor: pointer;
  opacity: 0;
  transform: translate3d(0, 0, 0) scale(0.95);
  transition: transform 0.2s ease, opacity 0.2s ease; }
  .gl-img-view-base.loaded {
    opacity: 1;
    transform: scale(1); }

.image-modal {
  background-color: none;
  bottom: 0;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: background-color 0.15s ease;
  z-index: 130; }
  .image-modal.active {
    background-color: rgba(0, 0, 0, 0.54); }
  .image-modal .close {
    background: rgba(255, 255, 255, 0.26);
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 12px 25px;
    position: absolute;
    right: 20px;
    text-transform: uppercase;
    top: 20px; }
    .image-modal .close:hover {
      background: rgba(255, 255, 255, 0.36);
      cursor: pointer; }
  .image-modal .overlay {
    background: rgba(0, 0, 0, 0.86);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 0.15s ease-in-out; }
  .image-modal .image {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    left: 0;
    position: absolute;
    top: 0;
    transform: translate3d(0, 0, 0); }
    .image-modal .image.animate {
      transition: all 0.15s ease-in-out; }

.gl-dropdown,
.gl-multiselect-dropdown {
  display: inline-block;
  max-height: 36px;
  position: relative;
  width: 100%; }
  .gl-dropdown:focus,
  .gl-multiselect-dropdown:focus {
    border-color: #2196f3;
    outline: 0; }
  .gl-dropdown .dropdown-toggle,
  .gl-multiselect-dropdown .dropdown-toggle {
    background-color: #fff;
    overflow: hidden;
    padding-right: 25px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
    .gl-dropdown .dropdown-toggle .gi,
    .gl-multiselect-dropdown .dropdown-toggle .gi {
      font-size: 1.4em;
      position: absolute;
      right: 3px;
      top: 8px; }
    .gl-dropdown .dropdown-toggle.disabled,
    .gl-multiselect-dropdown .dropdown-toggle.disabled {
      background-color: #eee;
      color: #999;
      pointer-events: none; }
      .gl-dropdown .dropdown-toggle.disabled:hover,
      .gl-multiselect-dropdown .dropdown-toggle.disabled:hover {
        border-color: #e8e8e8; }
      .gl-dropdown .dropdown-toggle.disabled .gi,
      .gl-multiselect-dropdown .dropdown-toggle.disabled .gi {
        color: #ccc; }

.disabled .gl-dropdown,
.disabled .gl-multiselect-dropdown {
  color: #ccc; }
  .disabled .gl-dropdown .gi,
  .disabled .gl-multiselect-dropdown .gi {
    color: #ccc; }

.dropdown-menu {
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.23), 0 8px 25px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: 1px solid #ccc;
  display: none;
  left: 0;
  list-style: none;
  max-height: 260px;
  min-width: 192px;
  overflow-y: auto;
  padding: 16px 0;
  position: absolute;
  text-align: left;
  top: 100%;
  width: 100%;
  z-index: 30; }
  .dropdown-menu .divider {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    height: 1px;
    margin: 8px 0; }
  .dropdown-menu .gi {
    font-size: 1.4em;
    vertical-align: middle;
    width: 40px; }
  .dropdown-menu > li {
    height: 32px;
    line-height: 32px;
    padding: 0 24px; }
    .dropdown-menu > li .dropdown-search {
      border: 0;
      width: 100%; }
    .dropdown-menu > li > a {
      color: rgba(0, 0, 0, 0.64);
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: top;
      white-space: nowrap;
      width: 100%; }
  .dropdown-menu .dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.05); }

.toast-container {
  position: fixed;
  z-index: 90; }
  .toast-container.bottom-left {
    bottom: 32px;
    left: 32px; }
  .toast-container.bottom-right {
    bottom: 32px;
    right: 32px; }
  .toast-container.bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%; }
    .toast-container.bottom-full-width .toast-container-inner {
      width: 100%; }
    .toast-container.bottom-full-width .gl-toast {
      width: 100%; }
  .toast-container.top-left {
    left: 32px;
    top: 32px; }
  .toast-container.top-right {
    right: 32px;
    top: 32px; }
  .toast-container.top-full-width {
    right: 0;
    top: 0;
    width: 100%; }
  .toast-container .toast-container-inner {
    bottom: inherit;
    position: fixed;
    top: inherit; }

.gl-toast {
  box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.24), 0 3px 8px rgba(0, 0, 0, 0.05);
  align-items: center;
  background-color: #323232;
  border-radius: 2px;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 48px;
  line-height: 20px;
  margin-top: 8px;
  max-width: 879px;
  padding: 0 24px;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .gl-toast.ng-enter {
    opacity: 0;
    transform: translate(0, 100%); }
    .gl-toast.ng-enter.from-top {
      transform: translate(0, -100%); }
    .gl-toast.ng-enter.ng-enter-active {
      opacity: 1;
      transform: translate(0, 0); }
  .gl-toast.ng-leave.ng-leave-active {
    opacity: 0;
    transform: translate(0, 100%); }
    .gl-toast.ng-leave.ng-leave-active.from-top {
      transform: translate(0, -100%); }
  .gl-toast .gl-toast-action {
    cursor: pointer;
    margin-left: 24px;
    text-transform: uppercase; }

.list-input {
  display: block;
  margin: 0 0 30px; }
  .list-input__head {
    align-items: center;
    display: flex; }
  .list-input__head-label.gl-form-label {
    margin-right: 0; }
  .list-input__head-spacer {
    flex-grow: 1; }
  .list-input__head-icon-btn {
    margin-left: 8px; }
  .list-input__lender {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    padding: 10px; }
    .list-input__lender-head {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin: 0 0 5px; }
      .list-input__lender-head-title {
        font-weight: 500;
        margin-right: 8px; }
      .list-input__lender-head-question {
        flex: 1; }
      .list-input__lender-head-btn {
        margin-top: -2px; }
    .list-input__lender-select-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px; }
    .list-input__lender-select-item {
      flex: 1 1 calc(50% - 10px);
      max-width: 100%;
      order: 1; }
      .list-input__lender-select-item.-first {
        order: 0; }
  .list-input__box {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px; }
  .list-input__row {
    align-items: center;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    display: flex;
    padding: 0 8px;
    position: relative;
    text-align: left;
    width: inherit; }
    .list-input__row:last-child {
      border-bottom: 0; }
  .list-input__image {
    max-width: 90px;
    margin: 7px 7px 7px 0; }
  .list-input__input {
    background: transparent;
    border: 0;
    flex-grow: 1;
    font-size: 13px;
    font-weight: 400;
    height: 40px;
    margin: 0;
    outline: 0; }
    .list-input__input.-heading {
      font-weight: 600;
      font-size: 15px;
      margin-left: -3px; }
    .list-input__input[disabled] {
      color: rgba(0, 0, 0, 0.4);
      font-style: italic; }
  .list-input__hidden-choice {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.4);
    font-size: 10px;
    margin-right: 5px;
    padding: 2px 5px; }
  .list-input__limit-reached {
    color: rgba(0, 0, 0, 0.4);
    font-size: 11px;
    font-weight: 400;
    line-height: 1;
    margin: 8px 0 0; }
  .list-input__feature {
    color: rgba(0, 0, 0, 0.15);
    margin-left: 5px; }
    .list-input__feature:hover {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.2); }
    .list-input__feature.-enabled {
      color: #000; }
  .list-input__score-input {
    margin: 0 5px; }
    .list-input__score-input > input {
      background: transparent;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      width: 50px;
      font-size: 12px;
      font-weight: 400;
      height: 24px;
      margin: 0;
      padding: 0 6px;
      outline: 0; }
      .list-input__score-input > input::-webkit-outer-spin-button, .list-input__score-input > input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .list-input__score-input > input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.2); }
      .list-input__score-input > input::-moz-placeholder {
        color: rgba(0, 0, 0, 0.2); }
      .list-input__score-input > input:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.2); }
      .list-input__score-input > input::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.2); }
      .list-input__score-input > input::placeholder {
        color: rgba(0, 0, 0, 0.2); }
      .list-input__score-input > input:focus {
        border-color: rgba(0, 0, 0, 0.2); }
      .list-input__score-input > input[disabled] {
        background-color: #eee;
        cursor: not-allowed; }
  .list-input__head-toggle {
    display: flex;
    align-items: center;
    margin-right: 8px;
    cursor: pointer; }
    .list-input__head-toggle g-checkbox {
      transform: scale(0.8); }
    .list-input__head-toggle span {
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      font-weight: 500;
      margin-left: 3px; }
  .list-input__randomize-control {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 8px; }
    .list-input__randomize-control span {
      font-size: 12px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6); }
    .list-input__randomize-control.-active span {
      color: #3aa3e3; }
  .list-input__randomize-popover {
    display: flex;
    padding: 10px 0 0 20px; }
  .list-input__randomize-block {
    padding-right: 40px; }
  .list-input__randomize-title {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 8px; }

.choice-fields__head {
  align-items: center;
  display: flex; }

.choice-fields__head-label {
  flex-grow: 1; }

.choice-fields__lender {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  margin: 4px 0 24px; }
  .choice-fields__lender-text {
    font-size: 15px; }
  .choice-fields__lender-question {
    font-weight: 400;
    font-size: 12px;
    margin: 0 0 12px;
    color: rgba(0, 0, 0, 0.3); }

.choice-fields__child-group {
  margin-left: 27px;
  margin-top: -15px; }

.numeric-fields__input, .numeric-fields__select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 1px solid #e5e9ec;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: 'Roboto';
  font-size: 13px;
  height: 37px;
  outline: 0;
  padding: 0 11px;
  transition: all 0.2s ease-out;
  width: 100%; }
  .numeric-fields__input:focus, .numeric-fields__select:focus {
    background-color: #f4f5f7;
    border-color: #f1f1f1; }
  .numeric-fields__input[disabled], .numeric-fields__select[disabled] {
    background-color: #eee; }

.numeric-fields__options {
  display: flex;
  margin: 0 -10px 20px; }

.numeric-fields__option {
  flex-basis: 130px;
  padding: 0 10px; }

.numeric-fields__field {
  display: block;
  position: relative; }

.numeric-fields__field-icon {
  pointer-events: none;
  position: absolute;
  right: 7px;
  top: 36px; }

.numeric-fields__label {
  font-size: 14px;
  margin: 0 0 4px; }

.numeric-fields__input.-custom, .numeric-fields__input.-diff {
  margin: 0 0 5px; }

.numeric-fields__diff {
  display: flex;
  margin: 0 -10px 20px; }
  .numeric-fields__diff > div {
    width: 50%;
    margin: 0 10px; }

.numeric-fields__choices {
  margin: 0 0 20px; }

.numeric-fields__choice {
  background: rgba(0, 0, 0, 0.08);
  border-radius: 11px;
  display: inline-block;
  font-size: 12px;
  height: 22px;
  line-height: 22px;
  margin: 0 4px 4px 0;
  padding: 0 10px; }

.matrix-fields {
  display: flex;
  margin: 0 -10px 20px; }
  .matrix-fields__child {
    flex-basis: 100%;
    flex-grow: 1;
    padding: 0 10px; }
  .matrix-fields__select-wrapper {
    width: 25%; }
    @media all and (max-width: 769px) {
      .matrix-fields__select-wrapper {
        width: 50%; } }
    @media all and (max-width: 435px) {
      .matrix-fields__select-wrapper {
        width: 100%; } }
  .matrix-fields__child-group {
    margin-left: 27px;
    margin-top: -15px; }

.title-field__top {
  align-items: center;
  display: flex; }

.title-field__label {
  flex-grow: 1; }

.title-selector {
  margin: 0 0 20px; }
  .title-selector__segment {
    display: inline; }
  .title-selector__text {
    display: inline;
    line-height: 32px; }
  .title-selector__dropdown {
    display: inline-block;
    width: 150px; }
  .title-selector__input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: inline-block;
    font-size: 15px;
    height: 32px;
    outline: 0;
    padding: 0 8px; }
    .title-selector__input::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.4); }
    .title-selector__input::-moz-placeholder {
      color: rgba(0, 0, 0, 0.4); }
    .title-selector__input:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.4); }
    .title-selector__input::-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.4); }
    .title-selector__input::placeholder {
      color: rgba(0, 0, 0, 0.4); }
    .title-selector__input:focus {
      border: 1px solid #3aa3e3; }

.text-fields__split {
  display: flex;
  align-items: flex-start;
  margin: 0 -5px; }
  .text-fields__split > div {
    flex: 1;
    padding: 0 5px; }

.scale-fields__child-group {
  margin-left: 27px;
  margin-top: -15px; }

.constant-sum-fields__split {
  display: flex;
  align-items: flex-start;
  margin: 0 -5px; }
  .constant-sum-fields__split > div {
    flex: 1;
    padding: 0 5px; }
    .constant-sum-fields__split > div.center {
      align-self: center; }

.constant-sum-total-field {
  margin-bottom: 0; }
  .constant-sum-total-field__top {
    height: 20px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-end; }
  .constant-sum-total-field__btn-clear {
    float: right;
    margin-top: -30px;
    margin-right: 10px; }

.location-fields__split {
  display: flex;
  align-items: flex-start;
  margin: 0 -5px; }
  .location-fields__split > div {
    flex: 1;
    padding: 0 5px;
    width: 100%;
    min-width: 0; }
  @media all and (max-width: 769px) {
    .location-fields__split {
      flex-direction: column; }
      .location-fields__split > div {
        margin: 5px 0; } }
  .location-fields__split.-margin-bottom {
    margin-bottom: 24px; }

.score-fields__split {
  display: flex;
  align-items: flex-start;
  margin: 0 -5px; }
  .score-fields__split > div {
    flex: 1;
    padding: 0 5px; }

.question-editor {
  position: relative; }
  .question-editor__lock {
    color: rgba(0, 0, 0, 0.5);
    text-align: center; }
    .question-editor__lock h4 {
      font-weight: 500; }
  .question-editor__image {
    width: 160px; }
  .question-editor__textarea {
    resize: none; }
  .question-editor__split {
    display: flex;
    align-items: flex-start; }
    .question-editor__split > div:not(:first-child) {
      margin-left: 20px; }

.radial__circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%; }

.external-panel-selector__dialog {
  max-width: 800px; }

.external-panel-selector__content {
  padding: 24px; }

.external-panel-selector__items {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -8px; }
  .external-panel-selector__items > div {
    padding: 0 8px 8px;
    flex-basis: 33.333%; }

.external-panel-selector__item {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 240px;
  cursor: pointer; }
  .external-panel-selector__item-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    flex: 1; }
  .external-panel-selector__item-name {
    font-size: 16px;
    font-weight: 600;
    text-align: center; }

g-icon {
  display: inline-block;
  line-height: 1;
  vertical-align: top; }

.g-icon {
  line-height: 1;
  text-align: center;
  transition: color 0.15s ease-out, text-shadow 0.15s ease-out; }
  .g-icon.-strike {
    position: relative; }
    .g-icon.-strike:after {
      content: '';
      position: absolute;
      top: -2px;
      left: 45%;
      width: 0;
      height: 100%;
      border-left: 2px solid;
      border-right: 2px solid #fff;
      transform: rotate(-45deg); }

.definition-popup__label {
  vertical-align: middle; }

.definition-popup__label-text {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.4); }

.definition-popup__label-icon {
  vertical-align: inherit;
  margin: 0 -3px 0 0;
  color: rgba(0, 0, 0, 0.4); }

.g-icon-button {
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: middle; }
  .g-icon-button:after {
    bottom: 0px;
    content: '';
    left: 0px;
    position: absolute;
    right: 0px;
    top: 0px; }
  .g-icon-button:hover {
    cursor: pointer; }

.gl-clouds {
  background: url(../img/gl-clouds-backdrop.png) repeat-x top left;
  background-size: auto 100%;
  display: block;
  overflow: hidden;
  position: relative; }
  .gl-clouds-group {
    -webkit-animation: anim 150s linear;
            animation: anim 150s linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    background: url(../img/gl-clouds-group.png) no-repeat top center;
    background-size: contain;
    bottom: 0;
    height: 520px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -40px;
    width: 1500px; }
    .gl-clouds-group.-repeat {
      -webkit-animation: anim-repeat 150s linear;
              animation: anim-repeat 150s linear;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite; }
  .gl-clouds-front {
    background: url(../img/gl-clouds-front.png) no-repeat 16% bottom;
    background-size: auto 40px;
    bottom: 0;
    height: 40px;
    left: 0;
    position: absolute;
    right: 0; }
    @media all and (min-width: 435px) {
      .gl-clouds-front {
        background-position: bottom center;
        background-size: auto 63px;
        height: 63px; } }
  .gl-clouds-content {
    position: relative; }

@-webkit-keyframes anim {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(-100%, 0, 0); } }

@keyframes anim {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(-100%, 0, 0); } }

@-webkit-keyframes anim-repeat {
  0% {
    transform: translate3d(100%, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes anim-repeat {
  0% {
    transform: translate3d(100%, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

.media-view {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.85);
  z-index: 100;
  /* Position child elements relative to this element */
  /* Create a pseudo element that uses padding-bottom to take up space */ }
  .media-view__image {
    max-width: 100%;
    height: auto;
    background: white;
    /* for transparent images */ }
  .media-view__download {
    position: fixed;
    bottom: 50px; }
    .media-view__download .g-btn {
      background: rgba(255, 255, 255, 0.9);
      color: rgba(0, 0, 0, 0.8); }
      .media-view__download .g-btn:hover:not(.-disabled), .media-view__download .g-btn:active:not(.-disabled), .media-view__download .g-btn:focus:not(.-disabled) {
        cursor: pointer;
        background: rgba(255, 255, 255, 0.8);
        color: black; }
  .media-view__content {
    width: 100%; }
  .media-view__media-wrapper {
    max-width: 720px;
    margin: 0 auto; }
  .media-view__aspect-ratio-box {
    position: relative; }
  .media-view__aspect-ratio-box::after {
    display: block;
    content: '';
    /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    padding-bottom: 56.25%; }
  .media-view__iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%; }
  .media-view__audio {
    width: 100%;
    padding: 0 20px; }

.hint {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  display: block;
  left: 0;
  margin: 0 3px;
  max-width: 380px;
  opacity: 0;
  padding: 12px 15px;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.1s ease-in-out;
  z-index: 999; }
  .hint__text {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center; }
  .hint__pointer {
    bottom: -10px;
    height: 10px;
    left: 0;
    margin-left: -7px;
    overflow: hidden;
    position: absolute; }
  .hint__pointer.-above {
    top: -10px; }
    .hint__pointer.-above .hint__pointer-visual {
      background: rgba(0, 0, 0, 0.8);
      bottom: -7px;
      height: 10px;
      position: relative;
      transform: rotate(45deg);
      width: 10px; }
  .hint__pointer.-below {
    bottom: -10px; }
    .hint__pointer.-below .hint__pointer-visual {
      background: rgba(0, 0, 0, 0.8);
      height: 10px;
      position: relative;
      top: -7px;
      transform: rotate(45deg);
      width: 10px; }

.asset-download {
  margin-top: 20%;
  width: 150px; }
  .asset-download__content {
    padding: 16px; }
  .asset-download__title {
    font-size: 18px;
    font-weight: 500;
    text-align: center; }
  .asset-download__desc {
    margin: 16px 0 0;
    text-align: center; }
  .asset-download__btn {
    background: #ff6600;
    border-radius: 4px;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin: 16px 0 0;
    padding: 8px;
    text-align: center; }
    .asset-download__btn:hover {
      color: #fff; }

.g-overlock {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1; }

.section-editor {
  position: relative; }
  .section-editor__image {
    width: 160px; }
  .section-editor__width-restrict {
    width: 50%; }
    @media all and (max-width: 769px) {
      .section-editor__width-restrict {
        width: 100%; } }
  .section-editor__split {
    display: flex;
    align-items: flex-start;
    margin: 0 -5px; }
    .section-editor__split > div {
      padding: 0 5px; }
    @media all and (max-width: 769px) {
      .section-editor__split {
        flex-direction: column; }
        .section-editor__split > div:not(:first-child) {
          margin-top: 20px; } }
  .section-editor__audio-container {
    display: flex; }
  .section-editor__audio-upload {
    margin-left: 10px; }

.intro-editor__image-input {
  width: 160px; }

.intro-editor__ssr {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 5px 0 0; }

errors {
  display: block; }

.errors {
  display: block;
  background: #fff;
  border-radius: 4px; }
  .errors__container {
    background: rgba(255, 0, 0, 0.1);
    border-radius: 4px;
    padding: 5px 15px; }
  .errors__error {
    font-size: 14px;
    margin: 5px 0;
    color: #eb4d5c; }
  .errors.-small .errors__container {
    padding: 2px 9px; }
  .errors.-small .errors__error {
    font-size: 12px; }

.field-error-message {
  display: block;
  margin: 4px 0;
  color: #eb4d5c;
  font-size: 11px;
  font-weight: 400;
  line-height: 1; }

@-webkit-keyframes spinnerRotator {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(270deg); } }

@keyframes spinnerRotator {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(270deg); } }

@-webkit-keyframes spinnerColors {
  0% {
    stroke: #4285f4; }
  25% {
    stroke: #de3e35; }
  50% {
    stroke: #f7c223; }
  75% {
    stroke: #1b9a59; }
  100% {
    stroke: #4285f4; } }

@keyframes spinnerColors {
  0% {
    stroke: #4285f4; }
  25% {
    stroke: #de3e35; }
  50% {
    stroke: #f7c223; }
  75% {
    stroke: #1b9a59; }
  100% {
    stroke: #4285f4; } }

@-webkit-keyframes spinnerDash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg); } }

@keyframes spinnerDash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg); } }

g-spinner {
  display: inline-block;
  line-height: 0;
  transition: none !important; }
  g-spinner.-block {
    display: block;
    text-align: center; }

.g-spinner {
  -webkit-animation: spinnerRotator 1.4s linear infinite;
          animation: spinnerRotator 1.4s linear infinite;
  transition: none !important; }
  .g-spinner__path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    -webkit-animation: spinnerDash 1.4s ease-in-out infinite;
            animation: spinnerDash 1.4s ease-in-out infinite;
    transition: none !important; }
    .g-spinner__path.-rainbow {
      -webkit-animation: spinnerDash 1.4s ease-in-out infinite, spinnerColors 5.6s ease-in-out infinite;
              animation: spinnerDash 1.4s ease-in-out infinite, spinnerColors 5.6s ease-in-out infinite; }

.g-spinner.ng-enter {
  display: none; }

.g-spinner.ng-enter.ng-enter-active {
  display: block; }

.g-btn {
  display: inline-block;
  vertical-align: top;
  border-radius: 4px;
  background: #ff6600;
  color: #fff; }
  .g-btn__content {
    display: flex;
    align-items: center;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    padding: 0 8px 0 12px;
    white-space: nowrap; }
  .g-btn__loading {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .g-btn__label, .g-btn__icon {
    margin-right: 4px; }
  .g-btn:hover:not(.-disabled), .g-btn:active:not(.-disabled), .g-btn:focus:not(.-disabled) {
    cursor: pointer;
    color: #fff;
    background: #ff6905; }
  .g-btn.-block {
    display: flex;
    justify-content: center;
    align-items: center; }
  .g-btn.-secondary {
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.7); }
    .g-btn.-secondary.-disabled {
      background: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.2);
      cursor: default; }
    .g-btn.-secondary:hover:not(.-disabled), .g-btn.-secondary:active:not(.-disabled), .g-btn.-secondary:focus:not(.-disabled) {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.7);
      background: rgba(0, 0, 0, 0.04); }
  .g-btn.-tertiary {
    background: transparent;
    color: rgba(0, 0, 0, 0.7); }
    .g-btn.-tertiary.-disabled {
      color: rgba(0, 0, 0, 0.2);
      cursor: default; }
    .g-btn.-tertiary:hover:not(.-disabled), .g-btn.-tertiary:active:not(.-disabled), .g-btn.-tertiary:focus:not(.-disabled) {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.7);
      background: rgba(0, 0, 0, 0.04); }
  .g-btn.-danger {
    background: #eb4d5c;
    color: #fff; }
    .g-btn.-danger:hover:not(.-disabled), .g-btn.-danger:active:not(.-disabled), .g-btn.-danger:focus:not(.-disabled) {
      cursor: pointer;
      color: #fff;
      background: #ec5260; }
  .g-btn.-small .g-btn__content {
    height: 26px;
    padding: 0 8px; }
  .g-btn.-small .g-btn__loading {
    height: 26px; }
  .g-btn.-small g-icon {
    margin-right: 1px; }
  .g-btn.-disabled {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.2);
    cursor: default; }

.alert-dialog {
  max-width: 430px !important;
  margin-top: 180px !important; }
  .alert-dialog__content {
    padding: 20px; }
  .alert-dialog__title {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 15px; }
  .alert-dialog__actions {
    padding: 0 20px 20px; }
  .alert-dialog__action {
    margin: 0 10px 0 0; }

.g-image {
  display: block;
  text-align: center;
  line-height: 0; }

.g-calendar {
  display: inline-block;
  width: 100%;
  max-width: 280px; }
  .g-calendar__head {
    display: flex;
    align-items: center;
    margin: 0 0 8px; }
  .g-calendar__title {
    flex-grow: 1;
    text-align: center;
    font-size: 16px;
    font-weight: 500; }
  .g-calendar__days {
    display: flex;
    align-items: center; }
  .g-calendar__day {
    flex-basis: 14.2857142857%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin: 4px 0; }
  .g-calendar__dates {
    display: flex;
    flex-wrap: wrap; }
  .g-calendar__date {
    width: 14.2857142857%;
    display: flex;
    position: relative;
    margin: 2px 0; }
    .g-calendar__date:before {
      content: '';
      float: left;
      padding-top: 100%; }
  .g-calendar__circle {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 50%; }
    .g-calendar__circle-inner {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 1; }
    .g-calendar__circle:hover {
      cursor: pointer; }
      .g-calendar__circle:hover .g-calendar__circle-inner {
        background: rgba(0, 0, 0, 0.05); }
    .g-calendar__circle.disabled {
      cursor: default; }
      .g-calendar__circle.disabled .g-calendar__circle-inner {
        color: rgba(0, 0, 0, 0.2);
        background: #fff; }
    .g-calendar__circle.active {
      background: #3aa3e3; }
      .g-calendar__circle.active .g-calendar__circle-inner {
        color: #fff; }
    .g-calendar__circle.left {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .g-calendar__circle.right {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .g-calendar__circle.selected .g-calendar__circle-inner {
      color: #3aa3e3;
      font-weight: 500;
      background: #fff; }

.g-checkbox {
  display: inline-block; }
  .g-checkbox__element {
    position: absolute;
    opacity: 0;
    left: -999999px; }

.g-checkbox-box {
  background: transparent;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  transition: border-color 0.1s ease-out, background-color 0.1s ease-out; }
  .g-checkbox-box__icon {
    opacity: 0;
    transition: opacity 0.1s ease-out, transform 0.1s ease-out 0.1s;
    transform: scale(0);
    color: #fff; }
  .g-checkbox-box.-selected {
    border-color: #3aa3e3;
    background: #3aa3e3; }
    .g-checkbox-box.-selected .g-checkbox-box__icon {
      opacity: 1;
      transform: scale(1); }
  .g-checkbox-box.-disabled {
    border-color: #e6e6e6;
    background-color: #e6e6e6; }
    .g-checkbox-box.-disabled .g-checkbox-box__icon {
      color: rgba(0, 0, 0, 0.4); }

.field-label {
  font-size: 14px;
  line-height: 1;
  margin: 24px 0 5px; }
  .field-label.-inline {
    margin: 0; }
  .field-label.-disabled {
    opacity: 0.54; }
  .field-label__icon {
    margin: -1px 0 0 0; }

.field-text {
  display: flex;
  align-items: center;
  background: #f2f2f2;
  border-radius: 4px;
  min-height: 36px;
  padding: 5px 10px;
  margin: 5px 0 24px; }
  .field-text input,
  .field-text textarea {
    flex-grow: 1;
    background: 0;
    outline: 0;
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: 'roboto';
    font-weight: 400;
    width: 100%; }
    .field-text input::-webkit-input-placeholder,
    .field-text textarea::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.2); }
    .field-text input::-moz-placeholder,
    .field-text textarea::-moz-placeholder {
      color: rgba(0, 0, 0, 0.2); }
    .field-text input:-ms-input-placeholder,
    .field-text textarea:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.2); }
    .field-text input::-ms-input-placeholder,
    .field-text textarea::-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.2); }
    .field-text input::placeholder,
    .field-text textarea::placeholder {
      color: rgba(0, 0, 0, 0.2); }
    .field-text input:disabled,
    .field-text textarea:disabled {
      color: rgba(0, 0, 0, 0.4); }
  .field-text input {
    height: 24px; }
    .field-text input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset; }
  .field-text textarea {
    resize: none; }
    .field-text textarea.-min-height {
      min-height: 84px; }
  .field-text.-inline {
    margin-top: 0;
    margin-bottom: 0; }
  .field-text.-small {
    min-height: 26px;
    padding: 0 10px; }
  .field-text__icon {
    color: rgba(0, 0, 0, 0.3);
    margin: 0 4px 0 -4px; }
  .field-text__prefix, .field-text__suffix {
    margin: -5px 0;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 400;
    font-size: 14px;
    align-self: stretch;
    display: flex;
    align-items: center; }
  .field-text__prefix {
    padding: 0 8px 0 0;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    margin-right: 8px; }
  .field-text__suffix {
    padding: 0 0 0 8px;
    border-left: 1px solid rgba(0, 0, 0, 0.05); }
    .field-text__suffix span {
      white-space: nowrap; }

.field-image {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 5px 0 24px;
  cursor: pointer; }
  .field-image__close {
    background: rgba(255, 255, 255, 0.9);
    color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px; }
    .field-image__close:hover {
      background: #fff;
      color: rgba(0, 0, 0, 0.8); }
  .field-image__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .field-image__placeholder-text {
      font-size: 12px;
      text-align: center;
      padding: 0 10px; }
  .field-image__input {
    -webkit-appearance: none;
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }
  .field-image__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.2s ease-out, transform 0.2s ease-out; }
  .field-image__error {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .field-image__error-msg {
    color: #eb4d5c;
    font-size: 11px;
    padding: 20px;
    text-align: center; }
  .field-image__loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.3); }

.field-file {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  height: 36px;
  padding: 5px 10px;
  margin: 5px 0 24px; }
  .field-file__loading {
    margin: 0 10px 0 0; }
  .field-file__placeholder {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4); }
  .field-file__filename {
    font-size: 14px;
    flex-grow: 1; }
  .field-file__error {
    font-size: 11px;
    color: #eb4d5c; }
  .field-file__action {
    margin: 0 0 0 5px; }
  .field-file__input {
    -webkit-appearance: none;
    left: -999999px;
    opacity: 0;
    position: absolute; }

.field-checkbox {
  display: flex;
  align-items: center;
  margin: 5px 0 24px; }
  .field-checkbox__box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 24px;
    height: 24px;
    margin: 0 10px 0 0; }
  .field-checkbox__icon {
    display: none; }
  .field-checkbox__control {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0; }
  .field-checkbox__box.-checked .field-checkbox__icon {
    display: inherit; }
  .field-checkbox.-inline {
    margin-top: 0;
    margin-bottom: 0; }

.field-radio {
  display: flex;
  align-items: center;
  margin: 5px 0 24px; }
  .field-radio__box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    width: 18px;
    height: 18px;
    margin: 0 10px 0 0; }
  .field-radio__selected {
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #3aa3e3; }
  .field-radio__box.-selected .field-radio__selected {
    display: inherit; }

.field-radio-set {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 9px 0 14px; }
  .field-radio-set .field-radio {
    margin: 0 20px 10px 0; }
  .field-radio-set.-vertical {
    flex-direction: column;
    align-items: stretch; }
    .field-radio-set.-vertical .field-radio {
      margin: 0 0 10px; }

.field-select {
  position: relative; }
  .field-select__control {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 8px;
    margin: 5px 0 24px; }
    .field-select__control.-inline {
      margin-top: 0;
      margin-bottom: 0; }
    .field-select__control.-open, .field-select__control:focus {
      border-color: #3aa3e3; }
    .field-select__control:hover {
      cursor: pointer; }
    .field-select__control.-disabled {
      border-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.4);
      cursor: default; }
  .field-select__caret {
    color: rgba(0, 0, 0, 0.1); }
    .field-select__caret.-open {
      transform: rotate(180deg);
      margin-top: -3px;
      color: rgba(0, 0, 0, 0.6); }
  .field-select__placeholder, .field-select__value {
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: 14px;
    line-height: 1.6; }
  .field-select__placeholder {
    color: rgba(0, 0, 0, 0.2); }
    .field-select__placeholder.-blank {
      opacity: 0; }
  .field-select__box {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 4px;
    z-index: 1; }
    .field-select__box.-limit-width {
      max-width: 320px; }
  .field-select__search {
    padding: 8px 8px 2px; }
  .field-select__list {
    flex: 1;
    padding: 8px 1px;
    overflow-y: auto;
    max-height: 197px; }
    .field-select__list.-large {
      max-height: 379px; }
  .field-select__divider {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    margin: 5px 0; }
  .field-select__option {
    padding: 8px 4px;
    display: flex;
    align-items: center; }
    .field-select__option-checkbox {
      margin: 0 0 0 8px; }
    .field-select__option-label {
      display: flex;
      align-items: center;
      font-size: 14px;
      flex-grow: 1;
      margin: 0 0 0 8px;
      color: rgba(0, 0, 0, 0.6);
      line-height: 1.3; }
      .field-select__option-label.-selected {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.7); }
    .field-select__option-tag {
      margin-left: 10px;
      padding: 4px 8px;
      font-size: 11px;
      font-weight: 500;
      border-radius: 10px;
      line-height: 1;
      text-transform: uppercase;
      color: #fff;
      background-color: #ff6600;
      white-space: nowrap; }
    .field-select__option-remove {
      height: 24px;
      margin: 0 8px 0 0; }
      .field-select__option-remove-icon {
        display: none; }
    .field-select__option:hover .field-select__option-remove-icon {
      display: inline-block; }
    .field-select__option:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.03); }
    .field-select__option.-disabled .field-select__option-label {
      color: rgba(0, 0, 0, 0.2); }
    .field-select__option.-disabled:hover {
      background: inherit; }
  .field-select__custom {
    border: 0;
    outline: 0;
    height: 39px;
    padding: 0 16px;
    font-size: 14px;
    width: 100%; }
    .field-select__custom::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.2); }
    .field-select__custom::-moz-placeholder {
      color: rgba(0, 0, 0, 0.2); }
    .field-select__custom:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.2); }
    .field-select__custom::-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.2); }
    .field-select__custom::placeholder {
      color: rgba(0, 0, 0, 0.2); }
  .field-select__actions {
    display: flex;
    align-items: center;
    padding: 12px 12px 0;
    margin: 0 0 -4px; }
  .field-select__action {
    margin-right: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer; }

.field-switch {
  display: inline-block;
  vertical-align: middle;
  height: 18px;
  width: 30px;
  border-radius: 9px;
  background-color: rgba(0, 0, 0, 0.15);
  padding: 2px;
  line-height: 1;
  transition: all 0.15s ease-out; }
  .field-switch__knob {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: white;
    transition: all 0.15s ease-out; }
  .field-switch.-on {
    background: #3aa3e3; }
    .field-switch.-on .field-switch__knob {
      margin-left: 12px; }

.field-section {
  border-left: 3px solid rgba(0, 0, 0, 0.05);
  padding: 1px 0 0 20px; }

.field-section-toggle {
  margin: 24px 0 5px; }
  .field-section-toggle__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    vertical-align: middle;
    margin-left: -2px; }
  .field-section-toggle__icon {
    color: rgba(0, 0, 0, 0.3);
    transform: rotate(-90deg);
    transition: all 0.15s ease-out;
    vertical-align: middle;
    margin-left: -4px; }
  .field-section-toggle.-open .field-section-toggle__icon {
    transform: rotate(0deg); }

.field-section-switch {
  margin: 24px 0 5px; }
  .field-section-switch__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    vertical-align: middle;
    margin-right: 8px; }

.field-video {
  display: block;
  background: #f2f2f2;
  border-radius: 4px;
  overflow: hidden;
  margin: 5px 0 24px;
  position: relative; }
  .field-video__aspect {
    padding-bottom: 56.25%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    position: relative; }
    .field-video__aspect-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column; }
  .field-video__player {
    color: rgba(0, 0, 0, 0.8); }
  .field-video__loading {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20x;
    background: rgba(0, 0, 0, 0.8); }
    .field-video__loading-phase {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8); }
    .field-video__loading-progress {
      font-size: 14px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.8);
      margin-left: 8px; }
  .field-video__error {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: rgba(0, 0, 0, 0.8); }
    .field-video__error-icon {
      color: #eb4d5c; }
    .field-video__error-msg {
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.8);
      margin-left: 4px; }
  .field-video__info {
    display: flex;
    align-items: center;
    min-height: 36px;
    padding: 5px 10px; }
    .field-video__info-icon {
      color: rgba(0, 0, 0, 0.6);
      margin: 0 4px -1px -4px;
      flex-shrink: 0; }
    .field-video__info-label, .field-video__info-placeholder {
      flex-grow: 1;
      font-size: 14px;
      font-family: 'roboto';
      font-weight: 400;
      width: 100%;
      margin-right: 20px; }
    .field-video__info-placeholder {
      color: rgba(0, 0, 0, 0.4); }
    .field-video__info-btn {
      flex-shrink: 0;
      line-height: 0; }
  .field-video__control {
    position: absolute;
    top: -9999px;
    left: -9999px;
    opacity: 0.0001; }
  .field-video__drop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 4px;
    background: rgba(0, 0, 0, 0.4); }
    .field-video__drop.-extra-inset {
      padding: 16px; }
    .field-video__drop-inner {
      flex: 1;
      border: 2px dashed rgba(255, 255, 255, 0.4);
      border-radius: 4px; }
  .field-video.-inline {
    margin-top: 0;
    margin-bottom: 0; }

.field-palette {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 0 24px; }
  .field-palette__color {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin: 0 4px 4px 0;
    border: 1px solid rgba(0, 0, 0, 0.05);
    position: relative; }
    .field-palette__color:hover {
      cursor: pointer; }
  .field-palette__add {
    width: 30px;
    height: 30px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.4); }
    .field-palette__add:hover {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.8); }
  .field-palette__edit {
    margin-bottom: 8px;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    background: white; }
    .field-palette__edit .color-picker-input {
      display: none; }
    .field-palette__edit .color-picker-panel {
      box-shadow: none; }
  .field-palette__fields {
    display: flex;
    align-items: center;
    margin-top: 8px; }
  .field-palette__input {
    width: 1px;
    /* fix overflow */
    flex: 1;
    margin-right: 8px; }
  .field-palette.-disabled .field-palette__color {
    cursor: default; }

.g-dialog-header__container.-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.g-dialog-header__top {
  display: flex;
  align-items: center;
  padding: 16px 24px; }

.g-dialog-header__back {
  margin: 0 16px 0 -5px; }

.g-dialog-header__content {
  flex-grow: 1; }

.g-dialog-header__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1; }

.g-dialog-header__sub-title {
  font-size: 16px; }

.g-dialog-header__message {
  font-size: 14px;
  padding: 0 25px 20px; }

.g-dialog-header__btn {
  margin: -10px 20px -9px 0; }

.g-dialog-header__close {
  align-self: flex-start; }

.g-dialog-header__tabs {
  margin: -5px 0 0px 0; }

.bundle-selector__dialog {
  max-width: 800px; }

.bundle-selector__loading {
  padding: 100px 30px; }

.bundle-selector__zero {
  padding: 30px; }

.bundle-selector__list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  margin: 0 -10px; }

.bundle-selector__list-item {
  flex-basis: 50%;
  padding: 0 10px 20px; }
  @media all and (max-width: 690px) {
    .bundle-selector__list-item {
      flex-basis: 100%; } }

.bundle-selector__card {
  display: block;
  margin: 0 0 20px;
  flex-basis: 320px; }

.bundle-selector__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 14px; }

.bundle-selector__link {
  display: inline-block;
  font-size: 14px;
  color: #ff6600;
  margin-left: 20px; }
  .bundle-selector__link:hover, .bundle-selector__link:active, .bundle-selector__link:focus {
    color: #cc5200; }

.bundle-card {
  border-radius: 4px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.03); }
  .bundle-card__title {
    display: flex;
    align-items: center;
    margin: 0 0 10px; }
  .bundle-card__name {
    font-size: 22px;
    font-weight: 500;
    margin: 0 0 0 5px;
    line-height: 1.1; }
  .bundle-card__desc {
    font-size: 16px;
    margin: 0 0 20px; }
  .bundle-card__feature {
    display: flex;
    align-items: flex-start;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 10px 0 0 0;
    margin: 0 0 10px; }
    .bundle-card__feature.-first {
      border-top: 0; }
    .bundle-card__feature-info {
      flex-grow: 1; }
    .bundle-card__feature-name {
      font-size: 16px;
      font-weight: 500; }
    .bundle-card__feature-desc {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      line-height: 1.3; }
    .bundle-card__feature-cost {
      flex-shrink: 0;
      flex-basis: 110px;
      font-size: 18px;
      font-weight: 500;
      text-align: right;
      margin-left: 10px; }
      .bundle-card__feature-cost.-detract {
        color: rgba(0, 0, 0, 0.3); }
      .bundle-card__feature-cost.-emphasis {
        color: #ff6600; }
      .bundle-card__feature-cost.-strike {
        text-decoration: line-through; }
  .bundle-card__footer {
    text-align: right;
    margin-top: 20px; }
  .bundle-card__terms {
    font-size: 11px;
    margin: 10px 0 0 0;
    text-align: left;
    color: rgba(0, 0, 0, 0.3); }

.micro-action {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .micro-action__text {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-weight: 500;
    margin-left: 8px; }

.pipe-chip-text__chip {
  display: inline-block;
  background: #3aa3e3;
  color: #fff;
  font-size: 14px;
  padding: 1px 6px;
  border-radius: 3px;
  margin: 0 3px;
  vertical-align: middle; }

.pipe-chip-text__hint {
  text-align: left;
  line-height: 1.5; }

.subscriber-promotion-thanks-dialog__dialog {
  max-width: 340px; }

.subscriber-promotion-thanks-dialog__content {
  padding: 24px; }

.translation-picker__dialog {
  max-width: 320px; }

.translation-picker__loading {
  padding: 20px; }

.translation-picker__error, .translation-picker__zero {
  font-size: 14px;
  line-height: 1.3;
  padding: 20px; }

.translation-picker__item {
  display: flex;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer; }
  .translation-picker__item-info {
    flex: 1;
    padding: 16px 0; }
  .translation-picker__item-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 4px; }
  .translation-picker__item-type {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.3);
    line-height: 1; }
  .translation-picker__item-selected-icon {
    color: #2ab27b; }
  .translation-picker__item-incomplete-icon {
    color: #eb4d5c; }
  .translation-picker__item.-disabled .translation-picker__item-label {
    color: rgba(0, 0, 0, 0.3); }

.translation-manager__dialog {
  max-width: 660px; }

.translation-manager__warning {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
  padding: 16px; }
  .translation-manager__warning-icon {
    margin-right: 16px; }
  .translation-manager__warning-text {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6); }
  .translation-manager__warning-btn {
    margin-left: 16px; }

.translation-manager__content {
  padding: 0 25px 25px; }

.translation-manager__actions {
  padding: 25px 0; }

.translation-manager__loading, .translation-manager__zero, .translation-manager__error {
  padding: 40px 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.4); }

.translation-manager__list {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px; }

.translation-manager__item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .translation-manager__item-info {
    flex: 1;
    padding: 9px 16px; }
    .translation-manager__item-info-label {
      font-size: 14px;
      font-weight: 500; }
    .translation-manager__item-info-type {
      font-size: 11px;
      color: rgba(0, 0, 0, 0.3); }
  .translation-manager__item-error {
    display: flex;
    align-items: center;
    margin-right: 32px; }
    .translation-manager__item-error-icon {
      margin-right: 2px; }
    .translation-manager__item-error-text {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.3); }
  .translation-manager__item-action {
    margin-right: 8px; }
    .translation-manager__item-action:last-child {
      margin-right: 16px; }
  .translation-manager__item:last-child {
    border-bottom: 0; }

.translation-editor__dialog {
  max-width: 660px; }

.translation-editor__header {
  display: flex;
  align-items: center;
  padding: 11px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
  .translation-editor__header-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    flex: 1; }
  .translation-editor__header-btn {
    margin-left: 8px; }

.translation-editor__content {
  padding: 0 25px 25px; }

.translation-editor__link {
  display: flex;
  align-items: flex-start; }
  .translation-editor__link-control {
    flex-shrink: 0; }
  .translation-editor__link-info {
    flex: 1; }
  .translation-editor__link-text {
    font-size: 12px;
    margin: 7px 0 0; }
  .translation-editor__link-warning {
    font-size: 12px;
    font-weight: 500; }
  .translation-editor__link-btn {
    margin-top: 16px; }

.translation-editor__search {
  display: flex;
  align-items: center;
  margin: 0 0 34px; }
  .translation-editor__search-filter {
    flex: 1; }
  .translation-editor__search-actions {
    display: flex;
    align-items: center;
    margin-left: 24px; }
  .translation-editor__search-action-checkbox {
    margin-left: 6px; }

.translation-editor__divide {
  margin: 40px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.translation-editor__customize {
  display: flex;
  align-items: flex-start; }
  .translation-editor__customize-btn {
    margin-right: 16px;
    flex-shrink: 0; }
  .translation-editor__customize-info {
    flex: 1; }
  .translation-editor__customize-text {
    font-size: 12px;
    margin: 0 0 8px; }

.video-player {
  position: relative; }
  .video-player__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .video-player__player {
    width: 100%;
    height: 100%; }
  .video-player__btn {
    position: absolute;
    color: white;
    cursor: pointer;
    transition: opacity 0.5s ease-out 0.1s; }
    .video-player__btn.-restart {
      bottom: 2px;
      left: 92px; }
    .video-player__btn.-rewind {
      bottom: 2px;
      left: 130px; }
    .video-player__btn.-visible {
      opacity: 1;
      transition: opacity 0.5s ease-out; }
  .video-player .video-js .vjs-control-bar {
    background-color: rgba(0, 0, 0, 0.1); }
  .video-player video {
    outline: 0; }

.g-file-input__input {
  -webkit-appearance: none;
  left: -999999px;
  opacity: 0;
  position: absolute; }

.g-color-dot {
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px 0 0; }

.theme-settings__bar {
  display: flex;
  align-items: center;
  margin: 5px 0 4px; }

.theme-settings__dropdown {
  flex: 1; }

.theme-settings__action {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer; }
  .theme-settings__action.-disabled {
    cursor: default;
    color: rgba(0, 0, 0, 0.2); }

.theme-settings__editor {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0 20px;
  margin-bottom: 24px; }

.theme-settings__label {
  display: flex;
  align-items: center;
  margin: 24px 0 5px;
  /* matches field-label */ }

.theme-settings__label-text {
  flex: 1; }

.theme-settings__label-action {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 8px; }
  .theme-settings__label-action:hover {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.6); }

.theme-question-dialog {
  padding: 20px; }
  .theme-question-dialog__dialog {
    max-width: 500px; }

.tags-picker__dialog {
  max-width: 320px; }

.tags-picker__zero {
  font-size: 14px;
  line-height: 1.3;
  padding: 20px; }

.tags-picker__list {
  padding: 20px 0; }

.tags-picker__description {
  font-size: 14px;
  line-height: 1.3;
  padding: 0 20px 16px; }

.tags-picker__search {
  padding: 0 20px 16px; }

.tags-picker__item {
  padding: 8px 24px;
  display: flex;
  align-items: center; }
  .tags-picker__item-label {
    font-size: 14px;
    margin: 0 0 0 8px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 1.3; }
    .tags-picker__item-label.-selected {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.7); }
  .tags-picker__item:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.03); }

.tags-picker__actions {
  padding: 16px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.05); }

.tags-picker__action {
  margin: 0 10px 0 0; }

.find-and-replace__dialog {
  max-width: 660px; }

.find-and-replace__content {
  padding: 24px 0 14px;
  min-height: 400px;
  display: flex;
  flex-direction: column; }

.find-and-replace__fields {
  padding: 0 24px; }

.find-and-replace__field {
  display: flex;
  align-items: center; }
  .find-and-replace__field:not(:first-child) {
    margin: 10px 0 0; }

.find-and-replace__field-text {
  flex: 1; }
  .find-and-replace__field-text.-with-action > .field-text {
    padding-right: 40px; }

.find-and-replace__field-action-wrapper {
  position: absolute;
  right: 135px; }

.find-and-replace__field-btn-wrapper {
  width: 100px;
  margin-left: 10px; }

.find-and-replace__field-action {
  background: 0;
  border: 0;
  font-size: 24px;
  height: 40px;
  margin: 0;
  padding: 0;
  width: 40px;
  outline: 0;
  color: rgba(0, 0, 0, 0.24); }
  .find-and-replace__field-action.-selected {
    color: #000; }
  .find-and-replace__field-action:hover:not(.-selected) {
    color: rgba(0, 0, 0, 0.5); }

.find-and-replace__field-error-text {
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
  margin: 16px 0 -8px; }

.find-and-replace__confirm-replace-text {
  margin: 0 0 22px;
  text-align: center; }

.find-and-replace__confirm-replace-actions {
  display: flex;
  justify-content: center; }
  .find-and-replace__confirm-replace-actions > g-btn {
    padding: 0 8px; }

.find-and-replace__zero {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 24px; }

.find-and-replace__zero-text {
  color: rgba(0, 0, 0, 0.3); }

.find-and-replace__results {
  padding: 24px 0 0; }

.find-and-replace__toolbar, .find-and-replace__result {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.find-and-replace__toolbar {
  display: flex;
  align-items: center;
  padding: 0 24px 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05); }

.find-and-replace__toolbar-content {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 14px 0; }
  .find-and-replace__toolbar-content.-preview {
    padding: 15px 24px; }

.find-and-replace__toolbar-gap {
  flex: 1; }

.find-and-replace__toolbar-info {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4); }

.find-and-replace__result {
  display: flex;
  align-items: center;
  padding: 5px 24px 5px 0; }
  .find-and-replace__result.-preview {
    padding: 7px 24px; }

.find-and-replace__result-text {
  flex: 1; }

.find-and-replace__result-content {
  color: rgba(0, 0, 0, 0.4); }
  .find-and-replace__result-content > b,
  .find-and-replace__result-content > s {
    font-weight: 500; }
  .find-and-replace__result-content > b {
    color: rgba(0, 0, 0, 0.8); }

.find-and-replace__toggle {
  padding: 14px 16px 14px 24px;
  cursor: pointer; }
