@font-face {
  font-family: 'glow-icons';
  src:
    url('../fonts/glow-icons.ttf?gdy0av') format('truetype'),
    url('../fonts/glow-icons.woff?gdy0av') format('woff'),
    url('../fonts/glow-icons.svg?gdy0av#glow-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="gi-"], [class*=" gi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'glow-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gi-calculator:before {
  content: "\e9a6";
}
.gi-radar:before {
  content: "\e99f";
}
.gi-rotate-left:before {
  content: "\e99d";
}
.gi-rotate-right:before {
  content: "\e99e";
}
.gi-stacked-chart:before {
  content: "\e99c";
}
.gi-cancel:before {
  content: "\e99b";
}
.gi-margin:before {
  content: "\e99a";
}
.gi-check-circle-outline:before {
  content: "\e993";
}
.gi-close-circle-outline:before {
  content: "\e994";
}
.gi-split:before {
  content: "\e992";
}
.gi-chat-2:before {
  content: "\e98b";
}
.gi-gauge:before {
  content: "\e98a";
}
.gi-bundle:before {
  content: "\e989";
}
.gi-menu-open:before {
  content: "\e988";
}
.gi-percent:before {
  content: "\e987";
}
.gi-check-circle:before {
  content: "\e986";
}
.gi-linkedin:before {
  content: "\e985";
}
.gi-file-pdf:before {
  content: "\e961";
}
.gi-file-excel:before {
  content: "\e962";
}
.gi-file-download:before {
  content: "\e963";
}
.gi-store:before {
  content: "\e95d";
}
.gi-library:before {
  content: "\e95e";
}
.gi-publication:before {
  content: "\e95f";
}
.gi-dollar:before {
  content: "\e960";
}
.gi-print:before {
  content: "\e95c";
}
.gi-pdf:before {
  content: "\e95b";
}
.gi-upgrade:before {
  content: "\e953";
}
.gi-wrench:before {
  content: "\e984";
}
.gi-activity:before {
  content: "\e900";
}
.gi-add:before {
  content: "\e901";
}
.gi-back:before {
  content: "\e902";
}
.gi-bar-chart:before {
  content: "\e903";
}
.gi-bell:before {
  content: "\e904";
}
.gi-bookmark:before {
  content: "\e905";
}
.gi-briefcase:before {
  content: "\e906";
}
.gi-building:before {
  content: "\e907";
}
.gi-bullhorn:before {
  content: "\e908";
}
.gi-camera:before {
  content: "\e909";
}
.gi-category:before {
  content: "\e90a";
}
.gi-charity:before {
  content: "\e90b";
}
.gi-chart:before {
  content: "\e90c";
}
.gi-chat:before {
  content: "\e90d";
}
.gi-chevron-left:before {
  content: "\e90e";
}
.gi-chevron-right:before {
  content: "\e90f";
}
.gi-close-feedback:before {
  content: "\e910";
}
.gi-close:before {
  content: "\e911";
}
.gi-cloud:before {
  content: "\e912";
}
.gi-cog:before {
  content: "\e913";
}
.gi-communicate:before {
  content: "\e914";
}
.gi-community-wall-2:before {
  content: "\e915";
}
.gi-community-wall:before {
  content: "\e916";
}
.gi-compare:before {
  content: "\e917";
}
.gi-complaint:before {
  content: "\e918";
}
.gi-contract:before {
  content: "\e919";
}
.gi-copy:before {
  content: "\e91a";
}
.gi-crowd:before {
  content: "\e91b";
}
.gi-dashboard-2:before {
  content: "\e91c";
}
.gi-dashboard:before {
  content: "\e91d";
}
.gi-date:before {
  content: "\e91e";
}
.gi-delete:before {
  content: "\e91f";
}
.gi-disconnected:before {
  content: "\e920";
}
.gi-done:before {
  content: "\e921";
}
.gi-dropdown:before {
  content: "\e922";
}
.gi-edit:before {
  content: "\e923";
}
.gi-exit:before {
  content: "\e924";
}
.gi-expand-less:before {
  content: "\e925";
}
.gi-expand-more:before {
  content: "\e926";
}
.gi-expand:before {
  content: "\e927";
}
.gi-fb:before {
  content: "\e928";
}
.gi-filter:before {
  content: "\e929";
}
.gi-flag:before {
  content: "\e92a";
}
.gi-forum:before {
  content: "\e92b";
}
.gi-forward:before {
  content: "\e92c";
}
.gi-gallery:before {
  content: "\e92d";
}
.gi-glow-man-circle:before {
  content: "\e92e";
}
.gi-glow-man:before {
  content: "\e92f";
}
.gi-glow-plus:before {
  content: "\e930";
}
.gi-glow:before {
  content: "\e931";
}
.gi-happy:before {
  content: "\e932";
}
.gi-help:before {
  content: "\e933";
}
.gi-hidden:before {
  content: "\e934";
}
.gi-hide-keyboard:before {
  content: "\e935";
}
.gi-home:before {
  content: "\e936";
}
.gi-idea:before {
  content: "\e937";
}
.gi-image:before {
  content: "\e938";
}
.gi-import-export:before {
  content: "\e939";
}
.gi-important:before {
  content: "\e93a";
}
.gi-indifferent:before {
  content: "\e93b";
}
.gi-info-outline:before {
  content: "\e93c";
}
.gi-info:before {
  content: "\e93d";
}
.gi-install:before {
  content: "\e93e";
}
.gi-kit:before {
  content: "\e93f";
}
.gi-link:before {
  content: "\e940";
}
.gi-list:before {
  content: "\e941";
}
.gi-location:before {
  content: "\e942";
}
.gi-lock:before {
  content: "\e943";
}
.gi-logic:before {
  content: "\e944";
}
.gi-mail:before {
  content: "\e945";
}
.gi-menu:before {
  content: "\e946";
}
.gi-mic:before {
  content: "\e947";
}
.gi-mood-1:before {
  content: "\e948";
}
.gi-mood-2:before {
  content: "\e949";
}
.gi-mood-3:before {
  content: "\e94a";
}
.gi-mood-4:before {
  content: "\e94b";
}
.gi-mood-5:before {
  content: "\e94c";
}
.gi-mood-6:before {
  content: "\e94d";
}
.gi-more:before {
  content: "\e94e";
}
.gi-my-location:before {
  content: "\e94f";
}
.gi-offers:before {
  content: "\e950";
}
.gi-other:before {
  content: "\e951";
}
.gi-pack:before {
  content: "\e952";
}
.gi-paste:before {
  content: "\e954";
}
.gi-person-add:before {
  content: "\e955";
}
.gi-person:before {
  content: "\e956";
}
.gi-pie-chart:before {
  content: "\e957";
}
.gi-plus-one:before {
  content: "\e958";
}
.gi-praise:before {
  content: "\e959";
}
.gi-preview:before {
  content: "\e95a";
}
.gi-private:before {
  content: "\e964";
}
.gi-project:before {
  content: "\e965";
}
.gi-public:before {
  content: "\e966";
}
.gi-publish:before {
  content: "\e967";
}
.gi-question:before {
  content: "\e968";
}
.gi-recommend:before {
  content: "\e969";
}
.gi-refresh:before {
  content: "\e96a";
}
.gi-registered:before {
  content: "\e96b";
}
.gi-reports:before {
  content: "\e96c";
}
.gi-request:before {
  content: "\e96d";
}
.gi-research:before {
  content: "\e96e";
}
.gi-review:before {
  content: "\e96f";
}
.gi-sad:before {
  content: "\e970";
}
.gi-save:before {
  content: "\e971";
}
.gi-scan:before {
  content: "\e972";
}
.gi-search:before {
  content: "\e973";
}
.gi-send:before {
  content: "\e974";
}
.gi-share:before {
  content: "\e975";
}
.gi-star-half:before {
  content: "\e976";
}
.gi-star-outline:before {
  content: "\e977";
}
.gi-star:before {
  content: "\e978";
}
.gi-suggestion:before {
  content: "\e979";
}
.gi-survey:before {
  content: "\e97a";
}
.gi-table:before {
  content: "\e97b";
}
.gi-time:before {
  content: "\e97c";
}
.gi-trophy:before {
  content: "\e97d";
}
.gi-twitter:before {
  content: "\e97e";
}
.gi-type:before {
  content: "\e97f";
}
.gi-unregistered:before {
  content: "\e980";
}
.gi-upvote:before {
  content: "\e981";
}
.gi-visible:before {
  content: "\e982";
}
.gi-web:before {
  content: "\e983";
}
.gi-text-input:before {
  content: "\e98c";
}
.gi-anchor:before {
  content: "\e98d";
}
.gi-exclusive:before {
  content: "\e98e";
}
.gi-independent:before {
  content: "\e98f";
}
.gi-play:before {
  content: "\e990";
}
.gi-pause:before {
  content: "\e991";
}
.gi-video:before {
  content: "\e995";
}
.gi-rewind-10:before {
  content: "\e996";
}
.gi-restart:before {
  content: "\e997";
}
.gi-open-link:before {
  content: "\e998";
}
.gi-antenna:before {
  content: "\e999";
}
.gi-palette:before {
  content: "\e9a0";
}
.gi-translate:before {
  content: "\e9a1";
}
.gi-mobile:before {
  content: "\e9a2";
}
.gi-volume-high:before {
  content: "\e9a3";
}
.gi-youtube:before {
  content: "\e9a4";
}
.gi-format-letter-case:before {
  content: "\e9a5";
}
.gi-map:before {
  content: "\e9a7";
}
